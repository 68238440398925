'use client'

import { Box, Container, Typography } from '@mui/material'

import { NotFoundIcon } from '../components/icons'

export default function NotFound() {
    return (
        <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h1" component="h1" style={{ color: 'black', fontSize: '12rem', fontWeight: 'bold' }}>
                            {'404'}
                        </Typography>
                        <Typography variant="h2" component="h2" fontWeight="bold">
                            {'Oops! Page not found.'}
                        </Typography>
                    </Box>
                    <NotFoundIcon style={{ fontSize: 100 }} />
                </Box>
            </Box>
        </Container>
    )
}
